import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BlockLoader } from '@components/ui/Loader';
import { FullHeightPage } from '@components/ui/Box';
import { Card, Typography } from '@components/ui';
import { FormattedMessage } from 'react-intl';

export const Wrapper = styled.div`
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled.div(
    ({ theme, cursor, noClick }) => `
	display: flex;
	padding: 20px ${theme.spacing.sm}px;
	margin: 0 -${theme.spacing.sm}px;
	justify-content: space-between;
	align-items: center;
	cursor: ${cursor || 'normal'};
	border-bottom: 1px solid ${theme.color.lightGrey};
	${noClick && `pointer-events: none;`};
	max-height: 75px;
	:last-of-type {
		border: 0;
	}
`,
);

export const RowBox = styled.div`
    ${Row}:last-child {
        border: 0;
    }
`;

export const Content = styled(Card)(
    ({ theme, noBottomOffset }) => `
	padding: ${theme.spacing.sm}px;
	${noBottomOffset && `padding-bottom: 0`};
	margin-bottom: 10px;
	flex-direction: column;
`,
);

const DefaultPage = FullHeightPage;

const Page = props => (
    <DefaultPage transparent={props.transparent} className={props.className}>
        <Wrapper fluid={props.$fluid}>
            {props.title && (
                <Title>
                    <FormattedMessage id={props.title} defaultMessage={props.title} />
                </Title>
            )}
            <BlockLoader {...props} />
        </Wrapper>
    </DefaultPage>
);

export const Title = styled(Typography).attrs(() => ({ variant: 'h1' }))`
    margin: 0;
    text-align: center;
`;

const TitleWrapper = styled.div(
    ({ theme, transparent }) => `
	color: ${theme.color.textPrimary};
	padding-bottom: 0;
	padding-top: 70px;
	background: ${transparent ? ' none' : theme.color.tertiary};
	${Title} {
		display: none;
	}
	${theme.media.md`
		${Title} {
			display: inherit;
			padding-bottom: 30px;
		}
	`}

`,
);

export const PageTitle = ({ id }) => (
    <TitleWrapper>
        <Title tabIndex="0">
            <FormattedMessage id={id} />
        </Title>
    </TitleWrapper>
);

const defaultProps = {
    loading: PropTypes.bool,
    transparent: PropTypes.bool,
};

Page.propTypes = { ...defaultProps, title: PropTypes.string };

export default Page;
