import { handleActions } from 'redux-actions';
import { deduplicateArray, handleListLoad } from '@utils/redux';
import { clientRoutes } from '@constants';
import * as types from './actionTypes';

const meRoute = clientRoutes.me();
const coachRoute = clientRoutes.coach();
const socialRoute = clientRoutes.social();

export const initialState = {
    list: [],
    [coachRoute]: {
        pending: false,
        items: [],
        pushes: 0,
        nextLink: '',
    },
    [socialRoute]: {
        items: [],
        pushes: 0,
        nextLink: null,
    },
};

const updateMessage = (state, action) => ({
    ...state,
    [action.meta.route]: {
        ...state[action.meta.route],
        items: state[action.meta.route].items.map(message => {
            const updatedMessage = {
                ...message,
                ...action.payload,
                body: message.body,
                lead: message.lead,
                media: message.media,
                time: message.time,
            };
            return action.payload.id === message.id ? updatedMessage : message;
        }),
    },
});

const toggleCoachPending = state => ({
    ...state,
    [coachRoute]: {
        ...state[coachRoute],
        pending: !state.pending,
    },
});

const setMessagesCount = (state, action) => ({
    ...state,
    [action.meta.route]: {
        ...state[action.meta.route],
        pushes: action.payload.counts || action.payload?.data?.counts || 0,
    },
});

export default handleActions(
    {
        [types.LOAD_MESSAGES_SUCCESS]: handleListLoad,
        [types.LOAD_SOCIAL_MESSAGES_SUCCESS]: (state, action) => ({
            ...state,
            [socialRoute]: {
                ...state[socialRoute],
                items: deduplicateArray([...state[socialRoute].items, ...action.payload.items]),
                nextLink: action.payload.nextLink,
            },
        }),
        [types.SKIP_MESSAGES_SUCCESS]: updateMessage,
        [types.SKIP_COACH_MESSAGE_REQUEST]: toggleCoachPending,
        [types.LOAD_SOCIAL_MESSAGES_COUNTS_SUCCESS]: setMessagesCount,
        [types.SKIP_COACH_MESSAGE_SUCCESS]: (state, action) => ({
            ...state,
            [coachRoute]: {
                ...state[coachRoute],
                items: state[coachRoute].items.map(message =>
                    action.payload.id === message.id ? { ...message, valid: false } : message,
                ),
                pending: false,
            },
        }),
        [types.ANSWER_COACH_MESSAGES_REQUEST]: toggleCoachPending,
        [types.ANSWER_COACH_MESSAGES_SUCCESS]: (state, action) => {
            const newState = updateMessage(state, action);
            return {
                ...newState,
                [coachRoute]: {
                    ...newState[coachRoute],
                    pending: false,
                },
            };
        },
        [types.LOAD_COACH_MESSAGES_SUCCESS]: handleListLoad,
        [types.LOAD_COACH_MESSAGES_REQUEST]: toggleCoachPending,
        [types.LOAD_COACH_COMPLETED_MESSAGES_SUCCESS]: (state, action) => ({
            ...state,
            [coachRoute]: {
                ...action.payload,
                items: action.payload.items.concat(state[coachRoute].items),
            },
        }),
        [types.CLEAR_UNSEEN_NOTIFICATIONS_REQUEST]: (state, action) => ({
            ...state,
            [socialRoute]: {
                items: state[socialRoute].items.map(item => {
                    if (!item.seen) {
                        return {
                            ...item,
                            seen: true,
                            completed: true,
                        };
                    }
                    return item;
                }),
                pushes: 0,
            },
        }),
        [types.LOAD_LAST_COACH_MESSAGES_REQUEST]: toggleCoachPending,
        [types.LOAD_LAST_COACH_MESSAGES_SUCCESS]: (state, action) => {
            if (!action.payload.message) {
                return {
                    ...state,
                    [coachRoute]: {
                        ...state[coachRoute],
                        pending: false,
                    },
                };
            }

            return {
                ...state,
                [coachRoute]: {
                    ...state[coachRoute],
                    items: state[coachRoute].items.concat(action.payload.message),
                    pending: false,
                },
            };
        },
        [types.SKIP_COACH_MESSAGE_FAILURE]: toggleCoachPending,
        [types.ANSWER_COACH_MESSAGES_FAILURE]: toggleCoachPending,
        [types.LOAD_COACH_MESSAGES_FAILURE]: toggleCoachPending,
        [types.LOAD_LAST_COACH_MESSAGES_FAILURE]: toggleCoachPending,
        [types.LOAD_MESSAGES_COUNT_SUCCESS]: setMessagesCount,
        [types.MARK_MESSAGE_SEEN_SUCCESS]: (state, action) => ({
            ...state,
            [meRoute]: {
                ...state[meRoute],
                items: state[meRoute].items.map(message =>
                    action.payload.id === message.id ? { ...message, seen: action.payload.seen } : message,
                ),
            },
        }),
    },
    initialState,
);
