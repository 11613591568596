import { getItem } from '@utils/storageUtils';
import { clientRoutes, DASHBOARD_CELLS } from '@constants';
import { INITIAL_CELLS } from 'src/routes/Me/Sections/Dashboard/utils';
import { createSelector } from 'reselect';
import { isAuthSelector } from '@services/auth/selectors';
import { mainFooterHeight, secondaryFooterHeight, cookieBannerHeight } from 'src/theme/vars/page';

const secondaryFooterRoutes = [
    clientRoutes.login(),
    clientRoutes.signup(),
    clientRoutes.root(),
    clientRoutes.phone(),
    clientRoutes.additionalConsent(),
    clientRoutes.confirmEmail(),
    clientRoutes.twoFactorAuth(),
    clientRoutes.mobileSurvey(),
];

export const dashboardCellsSelector = state => state.settings.dashboardCells;
export const storageCellsSelector = () => getItem(DASHBOARD_CELLS) || INITIAL_CELLS;

export const footerIsVisibleSelector = createSelector(
    [isAuthSelector, state => state.router],
    (isAuth, router) => isAuth || secondaryFooterRoutes.includes(router.location.pathname),
);
export const footerSettingsSelector = createSelector([state => state.router], router => {
    const isSecondary = secondaryFooterRoutes.includes(router.location.pathname);
    return {
        isSecondary,
        height: isSecondary ? secondaryFooterHeight : mainFooterHeight,
        cookieBanner: {
            height: cookieBannerHeight,
        },
    };
});

export const isSettingsUpdatingSelector = state => state.indicator.updating.settings;
