import * as types from '@services/user/actionTypes';
import { createAction } from '@utils/redux';

export const signUpRequest = createAction(types.LOAD_SIGNUP_REQUEST);
export const signUpSuccess = createAction(types.LOAD_SIGNUP_SUCCESS);
export const signUpFailure = createAction(types.LOAD_SIGNUP_FAILURE);

export const onboardingRequest = createAction(types.UPDATE_ONBOARDING_REQUEST);
export const onboardingSuccess = createAction(types.UPDATE_ONBOARDING_SUCCESS);
export const onboardingFailure = createAction(types.UPDATE_ONBOARDING_FAILURE);

export const updateUserRequest = createAction(types.UPDATE_USER_REQUEST);
export const updateUserSuccess = createAction(types.UPDATE_USER_SUCCESS);
export const updateUserFailure = createAction(types.UPDATE_USER_FAILURE);

export const updateUserProfileRequest = createAction(types.UPDATE_USER_PICTURE_REQUEST);
export const updateUserProfileSuccess = createAction(types.UPDATE_USER_PICTURE_SUCCESS);
export const updateUserProfileFailure = createAction(types.UPDATE_USER_PICTURE_FAILURE);

export const deleteUserRequest = createAction(types.DELETE_USER_REQUEST);
export const deleteUserSuccess = createAction(types.DELETE_USER_SUCCESS);
export const deleteUserFailure = createAction(types.DELETE_USER_FAILURE);

export const loadUserRequest = createAction(types.LOAD_USER_REQUEST);
export const loadUserSuccess = createAction(types.LOAD_USER_SUCCESS);
export const loadUserFailure = createAction(types.LOAD_USER_FAILURE);

export const loadUserFriendsRequest = createAction(types.LOAD_USER_FRIENDS_REQUEST);
export const loadUserFriendsSuccess = createAction(types.LOAD_USER_FRIENDS_SUCCESS);
export const loadUserFriendsFailure = createAction(types.LOAD_USER_FRIENDS_FAILURE);

export const loadPendingFriendsRequest = createAction(types.LOAD_PENDING_FRIENDS_REQUEST);
export const loadPendingFriendsSuccess = createAction(types.LOAD_PENDING_FRIENDS_SUCCESS);
export const loadPendingFriendsFailure = createAction(types.LOAD_PENDING_FRIENDS_FAILURE);

export const deleteUserAvatarRequest = createAction(types.DELETE_USER_AVATAR_REQUEST);
export const deleteUserAvatarSuccess = createAction(types.DELETE_USER_AVATAR_SUCCESS);
export const deleteUserAvatarFailure = createAction(types.DELETE_USER_AVATAR_FAILURE);

export const updateUserTermsRequest = createAction(types.UPDATE_USER_TERMS_REQUEST);
export const updateUserTermsSuccess = createAction(types.UPDATE_USER_TERMS_SUCCESS);
export const updateUserTermsFailure = createAction(types.UPDATE_USER_TERMS_FAILURE);

export const updateUserSubscriptionRequest = createAction(types.UPDATE_USER_SUBSCRIPTION_REQUEST);
export const updateUserSubscriptionSuccess = createAction(types.UPDATE_USER_SUBSCRIPTION_SUCCESS);
export const updateUserSubscriptionFailure = createAction(types.UPDATE_USER_SUBSCRIPTION_FAILURE);

export const clearError = createAction(types.CLEAR_ERROR_FIELD);

export const updateUserPhoneNumberRequest = createAction(types.UPDATE_USER_PHONE_NUMBER_REQUEST);
export const updateUserPhoneNumberSuccess = createAction(types.UPDATE_USER_PHONE_NUMBER_SUCCESS);
export const updateUserPhoneNumberFailure = createAction(types.UPDATE_USER_PHONE_NUMBER_FAILURE);

export const enableSMSCodeResend = createAction(types.ENABLE_SMS_CODE_RESEND);

export const updateUserPhoneValidationRequest = createAction(types.UPDATE_USER_PHONE_VALIDATION_REQUEST);
export const updateUserPhoneValidationSuccess = createAction(types.UPDATE_USER_PHONE_VALIDATION_SUCCESS);
export const updateUserPhoneValidationFailure = createAction(types.UPDATE_USER_PHONE_VALIDATION_FAILURE);

export const loadUserEmailConfirmationRequest = createAction(types.LOAD_USER_EMAIL_CONFIRMATION_REQUEST);
export const loadUserEmailConfirmationSuccess = createAction(types.LOAD_USER_EMAIL_CONFIRMATION_SUCCESS);
export const loadUserEmailConfirmationFailure = createAction(types.LOAD_USER_EMAIL_CONFIRMATION_FAILURE);

export const updateUserLanguageRequest = createAction(types.UPDATE_USER_LANGUAGE_REQUEST);
export const updateUserLanguageSuccess = createAction(types.UPDATE_USER_LANGUAGE_SUCCESS);
export const updateUserLanguageFailure = createAction(types.UPDATE_USER_LANGUAGE_FAILURE);
