import axios from 'axios';
import moment from 'moment';
import { dispatch } from 'src/store';
import { includes } from 'ramda';
import routes from '@constants/apiRoutes';
import { unAuthRoutes } from '@constants/clientRoutes';
import { logOut } from '@services/auth/actions';
import { UNAUTHORIZED, UNAUTHORIZED_BASIC } from 'src/common/api/status';
import { toApiFormat } from '@utils/date';

const makeRequest = axios;
const isAuth = includes(routes.auth);

axios.interceptors.response.use(
    res => res,
    error => {
        const { status } = error.response;
        const isUnAuthStatus = status === UNAUTHORIZED || status === UNAUTHORIZED_BASIC;
        if (isUnAuthStatus && !isAuth(error.config.url) && !unAuthRoutes.includes(window.location.pathname)) {
            dispatch(logOut());
        }
        return Promise.reject(error);
    },
);

export const get = (requestUrl, id = null, params = {}) =>
    makeRequest({
        baseURL: isAuth(requestUrl) ? '' : routes.baseURL,
        url: id ? `${requestUrl}/${id}` : requestUrl,
        method: 'get',
        params,
    });

export const post = (requestUrl, data = {}, id = null, params = {}) =>
    makeRequest({
        baseURL: isAuth(requestUrl) ? '' : routes.baseURL,
        url: id ? `${requestUrl}/${id}` : requestUrl,
        method: 'post',
        data,
        params,
    });

export const remove = (requestUrl, id = null, params = {}) =>
    makeRequest({
        baseURL: routes.baseURL,
        url: id ? `${requestUrl}/${id}` : requestUrl,
        method: 'delete',
        params,
    });

export const put = (requestUrl, id = null, data = {}) =>
    makeRequest({
        baseURL: routes.baseURL,
        url: id ? `${requestUrl}/${id}` : requestUrl,
        method: 'put',
        data,
    });

export const search = (entity, params) => get(routes[entity], null, params);

export const upload = (requestUrl, file = '', type = '', id = null, params = {}) => {
    const time = toApiFormat(moment());
    const data = new FormData();

    data.append('media', file);
    data.append('time', time);
    data.append('type', type);

    return makeRequest({
        baseURL: isAuth(requestUrl) ? '' : routes.baseURL,
        url: id ? `${requestUrl}/${id}` : requestUrl,
        method: 'post',
        data,
        params,
    });
};
