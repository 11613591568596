import { clientRoutes } from '@constants';
import { lazy } from 'react';

const ActivityPage = lazy(() => import('src/routes/Workouts/WorkoutPage'));
const AdditionalConsent = lazy(() => import('src/routes/AdditionalConsent'));
const ArticlePage = lazy(() => import('src/routes/Discover/Articles'));
const BiometricData = lazy(() => import('src/routes/Biometric'));
const ConfirmEmail = lazy(() => import('src/routes/ConfirmEmail'));
const ConfirmPrivacy = lazy(() => import('src/routes/ConfirmPrivacy'));
const Discover = lazy(() => import('src/routes/Discover'));
const Login = lazy(() => import('src/routes/Login'));
const Me = lazy(() => import('src/routes/Me'));
const Onboarding = lazy(() => import('src/routes/Onboarding'));
const PartnerContentPage = lazy(() => import('src/common/components/Cards/PartnerContent'));
const Phone = lazy(() => import('src/routes/Phone'));
const Profile = lazy(() => import('src/routes/Profile'));
const ProgramPage = lazy(() => import('src/common/components/Cards/Events/Event/EventPage'));
const SSO = lazy(() => import('src/routes/SSO'));
const SessionPage = lazy(() => import('src/common/components/Cards/Events/Sessions/SessionModal'));
const Signup = lazy(() => import('src/routes/Signup'));
const Social = lazy(() => import('src/routes/Social'));
const SubscrExpired = lazy(() => import('src/routes/SubscrExpired'));
const Terms = lazy(() => import('src/routes/Terms'));
const Challenge = lazy(() => import('src/routes/Challenge'));
const TwoFactorAuth = lazy(() => import('src/routes/2FA'));
const MobileSurvey = lazy(() => import('src/routes/MobileSurvey'));

const routes = {
    activityPage: { path: clientRoutes.activityPage, component: ActivityPage, exact: true },
    additionalConsent: { path: clientRoutes.additionalConsent, component: AdditionalConsent, exact: true },
    articlePage: { path: clientRoutes.articlePage, component: ArticlePage, exact: true },
    biometric: { path: clientRoutes.biometric, component: BiometricData, exact: true },
    callback: { path: clientRoutes.callback, component: SSO, exact: true },
    confirmEmail: { path: clientRoutes.confirmEmail, component: ConfirmEmail, exact: true },
    confirmPrivacy: { path: clientRoutes.confirmPrivacy, component: ConfirmPrivacy, exact: true },
    discover: { path: clientRoutes.discover, component: Discover, exact: true },
    eventPage: { path: clientRoutes.program, component: ProgramPage, exact: true },
    login: { path: clientRoutes.login, component: Login, exact: true },
    me: { path: clientRoutes.me, component: Me, exact: true },
    onboarding: { path: clientRoutes.onboarding, component: Onboarding, exact: true },
    partnerProgram: { path: clientRoutes.partnerProgram, component: PartnerContentPage, exact: true },
    phone: { path: clientRoutes.phone, component: Phone, exact: true },
    profile: { path: clientRoutes.profile, component: Profile, exact: true },
    sessionPage: { path: clientRoutes.programsession, component: SessionPage, exact: true },
    signup: { path: clientRoutes.signup, component: Signup, exact: true },
    social: { path: clientRoutes.social, component: Social, exact: true },
    subscription: { path: clientRoutes.subscription, component: SubscrExpired, exact: true },
    terms: { path: clientRoutes.terms, component: Terms, exact: true },
    challenge: { path: clientRoutes.challenge, component: Challenge, exact: true },
    challenges: { path: clientRoutes.challenges, component: Challenge, exact: true },
    event: { path: clientRoutes.event, component: ProgramPage, exact: true },
    eventsession: { path: clientRoutes.eventsession, component: SessionPage, exact: true },
    partnercontent: { path: clientRoutes.partnercontent, component: PartnerContentPage, exact: true },
    twoFactorAuth: { path: clientRoutes.twoFactorAuth, component: TwoFactorAuth, exact: true },
    mobileSurvey: { path: clientRoutes.mobileSurvey, component: MobileSurvey, exact: true },
};

export default routes;
