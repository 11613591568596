import { call, put, takeLatest } from 'redux-saga/effects';
import { updateMessage, updateMessages } from '@services/messages/api';
import { addErrorNotification } from '@services/notifications/actions';
import { clearUnseenNotificationsOptions } from '@constants/requestsOptions';
import * as actions from './actions';
import { getMessages } from './api';

const skipParams = {
    completed: true,
    seen: true,
};

export function* loadMessagesWorker({ payload, meta }) {
    try {
        const messages = yield call(getMessages, payload);
        yield put(actions.loadMessagesSuccess({ ...messages.data, meta }));
    } catch (e) {
        yield put(actions.loadMessagesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* skipMessage({ payload, meta }) {
    try {
        yield put(actions.skipMessagesSuccess({ ...payload, ...skipParams, meta }));
        yield call(updateMessage, { ...payload, ...skipParams });
    } catch (e) {
        yield put(actions.skipMessagesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* clearUnseenNotificationsWorker({ payload, meta }) {
    try {
        const message = yield call(updateMessages, { ...payload, ...clearUnseenNotificationsOptions });
        yield put(actions.clearUnseenNotificationsSuccess({ ...message, meta }));
        yield put(
            actions.loadSocialMessagesCountsRequest({ ...payload, ...clearUnseenNotificationsOptions, counts: '*' }),
        );
    } catch (e) {
        yield put(actions.clearUnseenNotificationsFailure(e.message));
    }
}

export function* loadMessageCountWorker({ payload, meta }) {
    try {
        const { data } = yield call(getMessages, payload);
        yield put(actions.loadMessagesCountSuccess({ data, meta }));
    } catch (e) {
        yield put(actions.loadMessagesCountFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadSocialMessagesWorker({ payload, meta }) {
    try {
        const messages = yield call(getMessages, payload);
        yield put(actions.loadSocialMessagesSuccess({ ...messages.data, meta }));
    } catch (e) {
        yield put(actions.loadSocialMessagesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadSocialMessagesCountsWorker({ payload, meta }) {
    try {
        const messages = yield call(getMessages, payload);
        yield put(actions.loadSocialMessagesCountsSuccess({ ...messages.data, meta }));
    } catch (e) {
        yield put(actions.loadSocialMessagesCountsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* markMessageSeenWorker({ payload, meta }) {
    try {
        const message = yield call(updateMessage, { ...payload, seen: true });
        yield put(actions.markMessageSeenSuccess({ ...message.data, meta }));
    } catch (e) {
        yield put(actions.markMessageSeenFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadMessagesRequest, loadMessagesWorker);
    yield takeLatest(actions.skipMessagesRequest, skipMessage);
    yield takeLatest(actions.clearUnseenNotificationsRequest, clearUnseenNotificationsWorker);
    yield takeLatest(actions.loadMessagesCountRequest, loadMessageCountWorker);
    yield takeLatest(actions.loadSocialMessagesRequest, loadSocialMessagesWorker);
    yield takeLatest(actions.loadSocialMessagesCountsRequest, loadSocialMessagesCountsWorker);
    yield takeLatest(actions.markMessageSeenRequest, markMessageSeenWorker);
}
