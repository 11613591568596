import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { ErrorMessage, ToggleButton } from '@components/ui/Form/Toggle/styled';
import { OptionalLabel } from '@components/ui/Form/styled';
import { FormattedMessage } from 'react-intl';
import { Container, LabelText } from './styled';

const Toggle = ({ name, disabled, label, onChange, className, required }) => {
    if (!name) return null;
    const [field, { error, touched }, helpers] = useField(name);
    const { value } = field;
    const handleClick = e => {
        e.preventDefault();
        if (onChange) {
            onChange(e);
        }
        helpers.setValue(!value);
    };
    const helperTextId = `${name}-helperText`;
    const labelId = `${name}-label`;

    return (
        <Container $disabled={disabled} className={className}>
            <ToggleButton
                onClick={handleClick}
                aria-label={name}
                aria-labelledby={labelId}
                aria-describedby={helperTextId}
                role="switch"
                aria-checked={value}
                type="button"
                id={name}
            >
                <span />
            </ToggleButton>
            {label && (
                <div>
                    <label id={labelId} htmlFor={name}>
                        <LabelText>{label}</LabelText>
                    </label>
                    <ErrorMessage id={helperTextId} aria-live="assertive" aria-relevant="additions removals">
                        {touched && error}
                    </ErrorMessage>
                    {!required && (
                        <OptionalLabel>
                            <FormattedMessage id="OPTIONAL" />
                        </OptionalLabel>
                    )}
                </div>
            )}
        </Container>
    );
};

Toggle.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Toggle;
