import {
    RELATION_STATUSES,
    MESSAGES_CATEGORY_SOCIAL,
    MESSAGES_CATEGORY_TODAY,
    MESSAGES_ORDER_BEFORE,
    PROGRAM_TYPE_EVENT,
    PROGRAM_TYPE_PARTNER_CONTENT,
} from '@constants/apiParams';
import { toApiFormat } from '@utils/date';
import { clientRoutes } from '@constants/clientRoutes';

const POSTS_LIMIT = 15;

export const allFriendsOptions = { friends: true, counts: '*' };
export const pendingFriendsOptions = { friends: true, counts: '*', status: RELATION_STATUSES.PENDING, deleted: true };
export const acceptedFriendsOptions = { friends: true, counts: '*', status: RELATION_STATUSES.ACCEPTED, deleted: true };

export const unseenNotificationsOptions = {
    categories: MESSAGES_CATEGORY_SOCIAL,
    counts: '*',
    seen: false,
    completed: false,
    limit: 1,
};

export const clearUnseenNotificationsOptions = {
    categories: MESSAGES_CATEGORY_SOCIAL,
    seen: false,
    completed: false,
    all: true,
    orderFrom: 0,
};

export const notificationsOptions = {
    categories: MESSAGES_CATEGORY_SOCIAL,
    counts: '*',
    orderBefore: MESSAGES_ORDER_BEFORE,
    withMedia: true,
};

export const todayMessagesOptions = {
    categories: MESSAGES_CATEGORY_TODAY,
    counts: '*',
    completed: false,
    withMedia: true,
    all: true,
    orderBefore: MESSAGES_ORDER_BEFORE,
};

export const goalsOptions = { statuses: 'ongoing,grace' };

export const achievementsOptions = { counts: '*', all: 'true' };

export const upcomingUserAchievementsOptions = { upcoming: true };

export const postsOptions = { shares: 'user', limit: POSTS_LIMIT };

export const eventsParams = {
    types: PROGRAM_TYPE_EVENT,
    counts: '*',
    statuses: 'ready,dismissed',
    endFrom: toApiFormat(new Date()),
    showTimeBefore: toApiFormat(new Date()),
    limit: 100,
    valid: true,
};

export const pastEventsParams = {
    types: PROGRAM_TYPE_EVENT,
    counts: '*',
    statuses: 'ready,dismissed',
    endBefore: toApiFormat(new Date()),
    showTimeBefore: toApiFormat(new Date()),
    limit: 100,
    valid: true,
};

export const partnerContentParams = {
    types: PROGRAM_TYPE_PARTNER_CONTENT,
    counts: '*',
    importanceBefore: 9999,
    statuses: 'ready,dismissed',
    showTimeBefore: toApiFormat(new Date()),
    limit: 200,
    valid: true,
};

export const articlesMePageParams = {
    limit: 5,
    meta: { route: clientRoutes.me() },
    types: 'article',
    showTimeBefore: toApiFormat(new Date()),
    valid: true,
    statuses: 'ready,dismissed',
};
