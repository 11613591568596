import { take, takeLatest, cancel, put, call, delay, select } from 'redux-saga/effects';
import * as actions from '@services/messages/actions';
import { head, last } from 'ramda';
import { deleteMessage, getMessages, updateMessage } from '@services/messages/api';
import { clientRoutes } from '@constants';

const CHAT_DELAY = 1500;
const coachRoute = clientRoutes.coach();

function* cancelCoachTask(task) {
    yield take(actions.cancelCompleteMessageRequest);
    yield cancel(task);
}

export function* loadCoachMessages({ payload, meta }) {
    try {
        const messages = yield call(getMessages, { ...payload, coach: true });
        yield put(actions.loadCoachMessagesSuccess({ ...messages.data, meta: { ...meta, skipLoad: true } }));
    } catch (e) {
        yield put(actions.loadCoachMessagesFailure(e.message));
    }
}

export function* loadLastUncompletedMessageWorker({ meta }) {
    yield delay(CHAT_DELAY);
    yield put(actions.loadLastCoachMessageRequest());
    const state = yield select();
    const messages = state.messages[coachRoute].items;
    const lastMessage = last(messages);
    try {
        if (!lastMessage.completed && lastMessage.valid) {
            yield put(actions.loadLastCoachMessageSuccess({}));
            return;
        }

        const { data } = yield call(getMessages, {
            next: true,
            orderFrom: lastMessage.order,
            meta: { route: coachRoute },
            coach: true,
        });
        yield put(actions.loadLastCoachMessageSuccess({ message: data, meta }));
    } catch (e) {
        yield put(actions.loadLastCoachMessageFailure(e.message));
    }
}

export function* loadCompletedCoachMessagesWorker({ payload, meta }) {
    try {
        const messages = yield call(getMessages, { ...payload, coach: true });
        yield put(actions.loadCoachCompletedMessagesSuccess({ ...messages.data, meta }));
    } catch (e) {
        yield put(actions.loadCoachCompletedMessagesFailure(e.message));
    }
}

export function* answerCoachMessageWorker({ payload, meta }) {
    const { message, answer } = payload;
    const data = {
        ...message,
        seen: true,
        completed: true,
        inputs: answer ? [{ ...head(message?.inputs), response: answer }] : message?.inputs,
    };

    try {
        yield put(actions.answerCoachMessageSuccess({ ...data, meta }));
        yield call(updateMessage, data);
    } catch (e) {
        yield put(actions.answerCoachMessageFailure(e.message));
    }
}

export function* completeLastCoachMessageWorker({ meta }) {
    try {
        const state = yield select();
        const messages = state.messages[coachRoute].items;
        const lastMessage = last(messages);
        if (!lastMessage.completed && !lastMessage.inputs.length && !meta.skipLoad) {
            yield put(actions.answerCoachMessageRequest({ message: lastMessage, meta: { route: coachRoute } }));
        }
    } catch (e) {
        yield put(actions.answerCoachMessageFailure(e.message));
    }
}

export function* proceedCoachMessagesWorker() {
    try {
        const state = yield select();
        const messages = state.messages[coachRoute].items;
        const lastMessage = last(messages);

        if (!lastMessage.completed && lastMessage.valid) {
            yield put(actions.loadLastCoachMessageSuccess({}));
            return;
        }

        yield put(actions.answerCoachMessageRequest({ message: lastMessage, meta: { route: coachRoute } }));
    } catch (e) {
        yield put(actions.proceedCoachMessagesFailure(e.message));
    }
}

export function* skipCoachMessageWorker({ payload, meta }) {
    try {
        yield put(actions.skipCoachMessageSuccess({ ...payload, meta }));
        yield call(deleteMessage, payload);
    } catch (e) {
        yield put(actions.skipCoachMessageFailure(e.message));
    }
}

export function* completeMessage() {
    while (true) {
        const task = yield takeLatest(
            [
                actions.skipCoachMessageSuccess,
                actions.loadCoachMessagesSuccess,
                actions.answerCoachMessageSuccess,
                actions.loadLastCoachMessageSuccess,
            ],
            completeLastCoachMessageWorker,
        );

        yield cancelCoachTask(task);
    }
}
export function* loadLastMessage() {
    while (true) {
        const task = yield takeLatest(
            [
                actions.skipCoachMessageSuccess,
                actions.loadCoachMessagesSuccess,
                actions.answerCoachMessageSuccess,
                actions.proceedCoachMessagesSuccess,
            ],
            loadLastUncompletedMessageWorker,
        );

        yield cancelCoachTask(task);
    }
}

export function* loadMessages() {
    while (true) {
        const task = yield takeLatest(actions.loadCoachMessagesRequest, loadCoachMessages);
        yield cancelCoachTask(task);
    }
}

export function* answerMessage() {
    while (true) {
        const task = yield takeLatest(actions.answerCoachMessageRequest, answerCoachMessageWorker);
        yield cancelCoachTask(task);
    }
}

export function* main() {
    yield takeLatest(actions.loadCoachCompletedMessagesRequest, loadCompletedCoachMessagesWorker);
    yield takeLatest(actions.skipCoachMessageRequest, skipCoachMessageWorker);
    yield takeLatest(actions.proceedCoachMessagesRequest, proceedCoachMessagesWorker);
}
