import styled from 'styled-components';

export const FullHeightPage = styled.div(
    ({ theme, transparent }) => `
    min-height: calc(100vh - ${theme.page.header.height}px);
	position: relative;
	background: ${transparent ? 'transparent' : theme.color.pageBackground};
	${theme.media.xsToMd`
	    min-height: calc(100vh - ${theme.page.header.heightSm}px);
	`}
`,
);
