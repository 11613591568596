import styled from 'styled-components';
import { Typography } from '@components/ui';
import { Message } from '@components/ui/Form/styled';

const CIRCLE_SIZE = 16;
const SLIDER_WIDTH = 42;
const SLIDER_HEIGHT = 17;
const TOGGLED_CIRCLE_POS = (CIRCLE_SIZE - SLIDER_HEIGHT) / 2;

export const Container = styled.div(
    ({ theme, $disabled }) => `
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
	button {
		position: relative;
		display: block;
		height: ${SLIDER_HEIGHT}px;
		width: ${SLIDER_WIDTH}px;
		background-color: ${theme.color.mediumGrey};
		margin-right: 12px;
		border-radius: 100px;
		cursor: pointer;
		transition: all 0.3s ease;
		box-sizing: border-box;
		border: 0;
		flex-shrink: 0;
		span {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: ${CIRCLE_SIZE}px;
			height: ${CIRCLE_SIZE}px;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
			border-style: solid;
			border-width: 0.5px;
			border-image-source: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.12),
				rgba(255, 255, 255, 0.06) 20%,
				rgba(255, 255, 255, 0)
			);
			border-image-slice: 1;
			background-color: #f1f1f1;
			border-radius: 100px;
			content: '';
			box-sizing: border-box;
			transition: all 0.3s ease;
		}
		&[role="switch"][aria-checked="true"] {
			background: ${theme.color.toggleBar};
			span {
				left: 25px;
				background: ${theme.color.toggleBullet};
			}
		}
		${$disabled &&
            ` background: #d5d5d5;
			pointer-events: none;
			span {
				background: #bcbdbc;
			}
		`}
	}`,
);

export const LabelText = styled(Typography).attrs(() => ({ variant: 'body' }))``;

export const ErrorMessage = styled(Message)`
    display: block;
    line-height: 12px;
`;

export const ToggleButton = styled.button`
    margin-top: 4px;
`;
