import React from 'react';

export * from './modals';
export * from './media';
export * from './metricsSystem';
export * from './icons';
export * from './statuses';
export * from './clientRoutes';
export * from './user';

export const SPACE_UNICODE = <>&ensp;</>;
export const DASH_UNICODE = <>&ndash;</>;
export const SLASH_UNICODE = <>&#47;</>;

export const USER_ID = 'USER_ID';
export const AGREEMENTS = 'AGREEMENTS';
export const USER_NAME = 'USER_NAME';
export const DASHBOARD_CELLS = 'DASHBOARD_CELLS';
export const BRANDING = 'BRANDING';
export const COOKIES_AGREEMENT = 'cookiesAgreement';
export const BLOOD_VALUES = 'BLOOD_VALUES';
export const SHOW_SPLITS = 'SHOW_SPLITS';
export const MGDL = 'mg/dl';
export const LOCALE = 'LOCALE';
export const NONE = 'common_none';
export const LEADERS = 'leaders';
export const IMPERIAL = 'imperial';
export const METRIC = 'metric';
export const MAX_QUANTITY = 99;
export const MAX_QUANTITY_LABEL = '99+';
export const MAX_RANKING = 99999;
export const FOLLOWER = 'follower';
export const LEADER = 'leader';
export const ME = 'Me';
export const SOCIAL = 'Social';
export const ACCOUNT = 'Account';
export const PROFILE = 'Profile';
export const SIGNUP = 'Signup';
export const ONBOARDING = 'Onboarding';
export const LOGIN = 'Login';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const PLATFORM_SETTINGS = 'PLATFORMSETTINGS';
export const MY_PROFILE = 'MY_PROFILE';
export const COMMON_SETTINGS = 'common_settings';
export const CHALLENGES = 'challenges';
export const WELCOME = 'Welcome';
export const SETTINGS = 'Settings';
export const LIMIT = 25;
export const REDIRECT_URL = 'REDIRECT_URL';
export const REDIRECT_PARAMS = 'REDIRECT_PARAMS';
export const POST = 'post';
export const MOVE = 'move';
export const ARTICLE = 'article';
export const REWARDSHOP = 'rewardshop';
export const REWARDSHOP_URL = 'REWARDSHOP_URL';
export const AGREE = 'agree';
export const REFUSE = 'refuse';
